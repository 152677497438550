import React from 'react'
import test1 from "../images/testi1.jpg"
import test2 from "../images/testi2.jpg"
import test3 from "../images/testi3.jpg"
import test4 from "../images/testi4.jpg"


const WhatCustomer = () => {
    return (
        <>
            <section class="as_customer_wrapper as_padderBottom80 as_padderTop80">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 text-center">
                            <h1 class="as_heading as_heading_center">आमचे ग्राहक काय म्हणतात</h1>
                            <p class="as_font14 as_margin0 as_padderBottom50">कृष्ण शास्त्री - आपल्याला भविष्यातील अडचणींवर सोपे उपाय आणि मार्गदर्शन देणारा विश्वासार्ह ज्योतिषी..</p>

                            <div class="row as_customer_slider">
                                <div class="col-lg-6 col-md-6">
                                    <div class="as_customer_box text-center">
                                        <span class="as_customer_img">
                                            <img src={test1} alt="" />
                                            <span><img src="assets/images/svg/quote1.svg" alt="" /></span>
                                        </span>
                                        <p class="as_margin0">कृष्णा शास्त्री यांनी दिलेली सेवा अत्यंत उत्कृष्ट होती. त्यांनी आमचे सर्व प्रोजेक्ट्स वेळेत आणि गुणवत्तेने पूर्ण केले.</p>
                                        <h3>अमित देशमुख</h3>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="as_customer_box text-center">
                                        <span class="as_customer_img">
                                            <img src={test2} alt="" />
                                            <span><img src="assets/images/svg/quote1.svg" alt="" /></span>
                                        </span>
                                        <p class="as_margin0">कृष्णा शास्त्री हे खरोखरच कुशल अभियंता आहेत. त्यांच्या मार्गदर्शनामुळे आमच्या कंपनीला खूप फायदा झाला आहे.</p>
                                        <h3>प्रिया पाटील</h3>

                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="as_customer_box text-center">
                                        <span class="as_customer_img">
                                            <img src={test3} alt="" />
                                            <span><img src="assets/images/svg/quote1.svg" alt="" /></span>
                                        </span>
                                        <p class="as_margin0">कृष्णा शास्त्री यांच्याबरोबर काम करणे खूपच सुखद अनुभव होता. त्यांच्या कामातील काटेकोरपणा आणि प्रामाणिकपणा विशेष उल्लेखनीय आहे.</p>
                                        <h3> संदीप शिंदे</h3>

                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="as_customer_box text-center">
                                        <span class="as_customer_img">
                                            <img src={test4} alt="" />
                                            <span><img src="assets/images/svg/quote1.svg" alt="" /></span>
                                        </span>
                                        <p class="as_margin0">कृष्णा शास्त्री यांच्या सेवा अतिशय विश्वासार्ह आहेत. त्यांनी दिलेले उपाय आम्हाला आमच्या कामात यशस्वी होण्यासाठी मदतगार ठरले.</p>
                                        <h3>राहुल सावंत</h3>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </>
    )
}

export default WhatCustomer