import React from 'react'
import { BsWhatsapp } from "react-icons/bs";


const Footer = () => {

    function handleMailto() {
        window.location.href =
            "mailto:krishnajoahi7274@gmail.com?subject=Your%20Subject%20Here&body=Your%20message%20here.";
    }


    function handleCallNow() {
        const phoneNumber = "7420907274";
        window.location.href = `tel:+${phoneNumber}`;
    }
    return (
        <>
            <section class="as_footer_wrapper as_padderTop80">
                <div class="container">
                    <div class="as_newsletter_wrapper as_padderBottom60">
                        <div class="row">
                            <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                                <h1 class="as_heading">आमचे वृत्तपत्र</h1>
                                <p>कृष्ण शास्त्री - आपले जीवन सुख, शांती आणि समृद्धीने मार्गदर्शक ज्योतिषी.</p>
                            </div>
                            <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12">
                                <div class="as_newsletter_box">
                                    <input type="text" name="" id="" class="form-control" placeholder="तुमचा ईमैल टाका..." />
                                    <a href="javascript:;" class="as_btn">आत्ता सभासद व्हा</a>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="as_footer_inner as_padderTop50 as_padderBottom80">
                        <div class="row">
                            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div class="as_footer_widget">
                                    <div class="as_footer_logo">
                                        <h3 style={{ fontWeight: "bold", color: "#ff7010" }}>कृष्णाजी शास्त्री</h3>

                                    </div>
                                    <p>कृष्ण शास्त्री - आपल्या जीवनातील प्रत्येक समस्येवर अचूक आणि प्रभावी उपाय देणारे ज्योतिषी, ज्यांच्या मार्गदर्शनामुळे सुख, शांती आणि समृद्धी नक्कीच मिळेल..</p>

                                    <div class="as_share_box">
                                        <p>आमच्या मागे या</p>
                                        <ul>
                                            <li><a href="javascript:;"><img src="assets/images/svg/facebook.svg" alt="" /></a></li>
                                            <li><a href="javascript:;"><img src="assets/images/svg/twitter.svg" alt="" /></a></li>
                                            <li><a href="javascript:;"><img src="assets/images/svg/google.svg" alt="" /></a></li>
                                            <li><a href="javascript:;"><img src="assets/images/svg/youtube.svg" alt="" /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div class="as_footer_widget">
                                    <h3 class="as_footer_heading">आमच्या सेवा</h3>

                                    <ul>

                                        <li><a href="service_detail.html">
                                            <span></span> कुंडली</a></li>
                                        <li><a href="service_detail.html"><span></span> रत्ने</a></li>
                                        <li><a href="service_detail.html"><span></span> रुद्राक्ष</a></li>
                                        <li><a href="service_detail.html"><span></span> जन्म पत्रिका</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div class="as_footer_widget">
                                    <h3 class="as_footer_heading">लगेच पोहचा</h3>

                                    <ul>
                                        <li><a href="about.html"><span>        </span> आमच्याबद्दल</a></li>
                                        <li><a href="blog.html"><span>         </span> पूजा माहिती</a></li>
                                        <li><a href="astrologer.html"><span>   </span> उत्पादने</a></li>
                                        <li><a href="appointment.html"><span>  </span> आमच्याशी संपर्क साधा</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div class="as_footer_widget">
                                    <h3 class="as_footer_heading">संपर्कात रहाण्यासाठी</h3>

                                    <ul class="as_contact_list">
                                        <li>
                                            <img src="assets/images/svg/map.svg" alt="" />
                                            <p>नाशिक त्र्यंबकेश्वर गुरुपीठ</p>
                                        </li>
                                        <li>
                                            <img src="assets/images/svg/address.svg" alt="" />
                                            <p>
                                                <a onClick={handleMailto}>krishnajoahi7274@gmail.com</a>
                                            </p>
                                        </li>
                                        <li>
                                            <img src="assets/images/svg/call.svg" alt="" />
                                            <p>
                                                <a onClick={handleCallNow}>7420907274</a><br></br>
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section class="as_copyright_wrapper text-center">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <p>&copy; कॉपीराइट  {new Date().getFullYear()},श्री स्वामी समर्थ ज्योतिष केंद्र , सर्व हक्क राखीव, <a href="#" style={{ color: "#ff7010" }}>कृष्णाजी शास्त्री</a> &nbsp;&nbsp;द्वारे विकसित <a href="#" style={{ color: "#ff7010" }}>Shree Digital Marketing Agency</a></p>
                        </div>
                    </div>
                </div>
            </section>

            <a
                href="https://wa.me/917420907274?text=Hello I found this number on your website and would like to know more about your services "
                target="_blank"
            >
                <BsWhatsapp className="wp-float-btn" />
            </a>

        </>
    )
}

export default Footer