import React from 'react'
import CommonTitle from '../components/CommonTitle'
import WhyChoose from '../components/WhyChoose'

import yantra1 from "../images/yantra1.jpeg"
import yantra2 from "../images/yantra2.webp"
import yantra3 from "../images/yantra3.webp"
import yantra4 from "../images/yantra4.webp"
import yantra5 from "../images/yantra5.jpg"
import yantra6 from "../images/yantra6.webp"

import stone1 from "../images/stone1.png"
import stone2 from "../images/stone2.png"
import stone3 from "../images/stone3.png"
import stone4 from "../images/stone4.png"
import stone5 from "../images/stone5.png"
import stone6 from "../images/stone6.png"
import stone7 from "../images/stone7.png"
import stone8 from "../images/stone8.png"
import stone11 from "../images/stone9.png"
import Rudraksh1 from "../images/rudra1.png"
import Rudraksh2 from "../images/rudra2.png"
import Rudraksh3 from "../images/rudra3.png"
import Rudraksh4 from "../images/rudra4.png"
import Rudraksh5 from "../images/rudra5.png"
import Rudraksh6 from "../images/rudra6.png"
import Rudraksh7 from "../images/rudra7.png"
import Rudraksh8 from "../images/rudra8.png"
import Rudraksh9 from "../images/rudra9.png"


const Shop = () => {

    const openWhatsApp = () => {
        const url = "https://wa.me/917420907274?text=Hello I found this number on your website and would like to know more about your services";
        window.open(url, '_blank');
    }


    return (
        <>
            <div class="as_main_wrapper">
                <CommonTitle title="खरेदी करा" />
                <section class="as_product_single_wrapper as_padderBottom80 as_padderTop80">
                    <div class="container">
                        <div class="row">
                            <div style={{ textAlign: "center" }}>
                                <h1 class="as_heading as_heading_center">रत्ने</h1>
                                <p class="as_font14 as_margin0 as_padderBottom20">आमच्याकडे सिद्ध जप अनुष्ठान रत्न मिळतील.</p>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6">
                                <div class="as_product_box">
                                    <div class="as_product_img">
                                        <img src={stone1} alt="" class="img-responsive" />
                                    </div>
                                    <div>
                                        <h4 class="as_subheading">रुबी</h4>
                                        <span class="as_price">₹20 <del>₹80</del> <span class="as_orange">(60% off)</span></span>
                                    </div>
                                    <button class="as_btn" style={{ marginTop: "15px" }} onClick={openWhatsApp}>खरेदी करा</button>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6">
                                <div class="as_product_box">
                                    <div class="as_product_img">
                                        <img src={stone2} alt="" class="img-responsive" />
                                    </div>
                                    <div>
                                        <h4 class="as_subheading">पुष्कराज</h4>
                                        <span class="as_price">₹20 <del>₹80</del> <span class="as_orange">(60% off)</span></span>
                                    </div>
                                    <button class="as_btn" style={{ marginTop: "15px" }} onClick={openWhatsApp}>खरेदी करा</button>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6">
                                <div class="as_product_box">
                                    <div class="as_product_img">
                                        <img src={stone7} alt="" class="img-responsive" />
                                    </div>
                                    <div>
                                        <h4 class="as_subheading">पन्ना</h4>
                                        <span class="as_price">₹20 <del>₹80</del> <span class="as_orange">(60% off)</span></span>
                                    </div>
                                    <button class="as_btn" style={{ marginTop: "15px" }} onClick={openWhatsApp}>खरेदी करा</button>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6">
                                <div class="as_product_box">
                                    <div class="as_product_img">
                                        <img src={stone3} alt="" class="img-responsive" />
                                    </div>
                                    <div>
                                        <h4 class="as_subheading">नीलम</h4>
                                        <span class="as_price">₹20 <del>₹80</del> <span class="as_orange">(60% off)</span></span>
                                    </div>
                                    <button class="as_btn" style={{ marginTop: "15px" }} onClick={openWhatsApp}>खरेदी करा</button>

                                </div>
                            </div>



                            <div class="col-lg-3 col-md-6 col-sm-6">
                                <div class="as_product_box">
                                    <div class="as_product_img">
                                        <img src={stone5} alt="" class="img-responsive" />
                                    </div>
                                    <div>
                                        <h4 class="as_subheading">रुबी</h4>
                                        <span class="as_price">₹20 <del>₹80</del> <span class="as_orange">(60% off)</span></span>
                                    </div>
                                    <button class="as_btn" style={{ marginTop: "15px" }} onClick={openWhatsApp}>खरेदी करा</button>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6">
                                <div class="as_product_box">
                                    <div class="as_product_img">
                                        <img src={stone4} alt="" class="img-responsive" />
                                    </div>
                                    <div>
                                        <h4 class="as_subheading">मोती</h4>
                                        <span class="as_price">₹20 <del>₹80</del> <span class="as_orange">(60% off)</span></span>
                                    </div>
                                    <button class="as_btn" style={{ marginTop: "15px" }} onClick={openWhatsApp}>खरेदी करा</button>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6">
                                <div class="as_product_box">
                                    <div class="as_product_img">
                                        <img src={stone11} alt="" class="img-responsive" />
                                    </div>
                                    <div>
                                        <h4 class="as_subheading">मुंगा</h4>
                                        <span class="as_price">₹20 <del>₹80</del> <span class="as_orange">(60% off)</span></span>
                                    </div>
                                    <button class="as_btn" style={{ marginTop: "15px" }} onClick={openWhatsApp}>खरेदी करा</button>

                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6">
                                <div class="as_product_box">
                                    <div class="as_product_img">
                                        <img src={stone8} alt="" class="img-responsive" />
                                    </div>
                                    <div>
                                        <h4 class="as_subheading">गोमेद</h4>
                                        <span class="as_price">₹20 <del>₹80</del> <span class="as_orange">(60% off)</span></span>
                                    </div>
                                    <button class="as_btn" style={{ marginTop: "15px" }} onClick={openWhatsApp}>खरेदी करा</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <WhyChoose />

                <section class="as_product_wrapper as_padderBottom80 as_padderTop80 as_product_page">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 text-center">
                                <h1 class="as_heading as_heading_center">यंत्रे</h1>
                                <p class="as_font14 as_margin0 as_padderBottom20">आमच्याकडे सिद्ध जप अनुष्ठान झालेले यंत्र मिळतील.</p>

                                <div class="row as_product_slider">
                                    <div class="col-lg-3 col-md-6">
                                        <div class="as_product_box">
                                            <div class="as_product_img">
                                                <img src={yantra1} alt="" class="img-responsive" />
                                            </div>
                                            <div>
                                                <h4 class="as_subheading">श्री सम्पूर्ण कालसर्प यंत्र</h4>
                                                <span class="as_price">₹20 <del>₹80</del> <span class="as_orange">(60% off)</span></span>
                                            </div>
                                            <button class="as_btn" style={{ marginTop: "15px" }} onClick={openWhatsApp}>खरेदी करा</button>

                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6">
                                        <div class="as_product_box">
                                            <div class="as_product_img">
                                                <img src={yantra2} alt="" class="img-responsive" />
                                            </div>
                                            <div>
                                                <h4 class="as_subheading">श्री सम्पूर्ण वास्तुयन्त्रम यंत्र</h4>
                                                <span class="as_price">₹20 <del>₹80</del> <span class="as_orange">(60% off)</span></span>
                                            </div>
                                            <button class="as_btn" style={{ marginTop: "15px" }} onClick={openWhatsApp}>खरेदी करा</button>

                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6">
                                        <div class="as_product_box">
                                            <div class="as_product_img">
                                                <img src={yantra3} alt="" class="img-responsive" />
                                            </div>
                                            <div>
                                                <h4 class="as_subheading">श्री सम्पूर्ण नवग्रहयंत्रम </h4>
                                                <span class="as_price">₹20 <del>₹80</del> <span class="as_orange">(60% off)</span></span>
                                            </div>
                                            <button class="as_btn" style={{ marginTop: "15px" }} onClick={openWhatsApp}>खरेदी करा</button>

                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6">
                                        <div class="as_product_box">
                                            <div class="as_product_img">
                                                <img src={yantra4} alt="" class="img-responsive" />
                                            </div>
                                            <div>

                                                <h4 class="as_subheading">श्री संम्पूर्ण यंत्रम</h4>
                                                <span class="as_price">₹20 <del>₹80</del> <span class="as_orange">(60% off)</span></span>
                                            </div>
                                            <button class="as_btn" style={{ marginTop: "15px" }} onClick={openWhatsApp}>खरेदी करा</button>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6">
                                        <div class="as_product_box">
                                            <div class="as_product_img">
                                                <img src={yantra5} alt="" class="img-responsive" />
                                            </div>
                                            <div>
                                                <h4 class="as_subheading">श्री सम्पूर्ण  यंत्र</h4>
                                                <span class="as_price">₹20 <del>₹80</del> <span class="as_orange">(60% off)</span></span>
                                            </div>
                                            <button class="as_btn" style={{ marginTop: "15px" }} onClick={openWhatsApp}>खरेदी करा</button>

                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6">
                                        <div class="as_product_box">
                                            <div class="as_product_img">
                                                <img src={yantra6} alt="" class="img-responsive" />
                                            </div>
                                            <div>
                                                <h4 class="as_subheading">श्री सम्पूर्ण व्यापारवृद्धी यंत्र</h4>
                                                <span class="as_price">₹20 <del>₹80</del> <span class="as_orange">(60% off)</span></span>
                                            </div>
                                            <button class="as_btn" style={{ marginTop: "15px" }} onClick={openWhatsApp}>खरेदी करा</button>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="as_product_wrapper as_padderBottom80 as_padderTop80 as_product_page">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 text-center">
                                <h1 class="as_heading as_heading_center">रुद्राक्ष</h1>
                                <p class="as_font14 as_margin0 as_padderBottom20">रुद्राक्ष १ मुखी ते ९ मुखी व रुद्राक्ष माळा उपलब्ध . आमच्या कडे सिद्ध जप अनुष्ठान रुद्राक्ष मिळेल</p>

                                <div class="row as_product_slider">
                                    <div class="col-lg-3 col-md-6">
                                        <div class="as_product_box">
                                            <div class="as_product_img">
                                                <img src={Rudraksh1} alt="" class="img-responsive" />
                                            </div>
                                            <div>
                                                <h4 class="as_subheading">1 मुखी (रुद्राक्ष)</h4>
                                                <span class="as_price">₹20 <del>₹80</del> <span class="as_orange">(60% off)</span></span>
                                            </div>
                                            <button class="as_btn" style={{ marginTop: "15px" }} onClick={openWhatsApp}>खरेदी करा</button>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6">
                                        <div class="as_product_box">
                                            <div class="as_product_img">
                                                <img src={Rudraksh2} alt="" class="img-responsive" />

                                            </div>
                                            <div>

                                                <h4 class="as_subheading">२ मुखी (रुद्राक्ष)</h4>
                                                <span class="as_price">₹20 <del>₹80</del> <span class="as_orange">(60% off)</span></span>
                                            </div>
                                            <button class="as_btn" style={{ marginTop: "15px" }} onClick={openWhatsApp}>खरेदी करा</button>

                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6">
                                        <div class="as_product_box">
                                            <div class="as_product_img">
                                                <img src={Rudraksh3} alt="" class="img-responsive" />

                                            </div>
                                            <div>
                                                <h4 class="as_subheading">३ मुखी (रुद्राक्ष)</h4>
                                                <span class="as_price">₹20 <del>₹80</del> <span class="as_orange">(60% off)</span></span>
                                            </div>
                                            <button class="as_btn" style={{ marginTop: "15px" }} onClick={openWhatsApp}>खरेदी करा</button>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6">
                                        <div class="as_product_box">
                                            <div class="as_product_img">
                                                <img src={Rudraksh4} alt="" class="img-responsive" />
                                            </div>
                                            <div>
                                                <h4 class="as_subheading">४ मुखी (रुद्राक्ष)</h4>
                                                <span class="as_price">₹20 <del>₹80</del> <span class="as_orange">(60% off)</span></span>
                                            </div>
                                            <button class="as_btn" style={{ marginTop: "15px" }} onClick={openWhatsApp}>खरेदी करा</button>

                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6">
                                        <div class="as_product_box">
                                            <div class="as_product_img">
                                                <img src={Rudraksh5} alt="" class="img-responsive" />
                                            </div>
                                            <div>
                                                <h4 class="as_subheading">५ मुखी (रुद्राक्ष)</h4>
                                                <span class="as_price">₹20 <del>₹80</del> <span class="as_orange">(60% off)</span></span>
                                            </div>
                                            <button class="as_btn" style={{ marginTop: "15px" }} onClick={openWhatsApp}>खरेदी करा</button>

                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6">
                                        <div class="as_product_box">
                                            <div class="as_product_img">
                                                <img src={Rudraksh6} alt="" class="img-responsive" />
                                            </div>
                                            <div>
                                                <h4 class="as_subheading">६ मुखी (रुद्राक्ष)</h4>
                                                <span class="as_price">₹20 <del>₹80</del> <span class="as_orange">(60% off)</span></span>
                                            </div>
                                            <button class="as_btn" style={{ marginTop: "15px" }} onClick={openWhatsApp}>खरेदी करा</button>

                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6">
                                        <div class="as_product_box">
                                            <div class="as_product_img">
                                                <img src={Rudraksh7} alt="" class="img-responsive" />
                                            </div>
                                            <div>
                                                <h4 class="as_subheading">७ मुखी (रुद्राक्ष)</h4>
                                                <span class="as_price">₹20 <del>₹80</del> <span class="as_orange">(60% off)</span></span>
                                            </div>
                                            <button class="as_btn" style={{ marginTop: "15px" }} onClick={openWhatsApp}>खरेदी करा</button>

                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6">
                                        <div class="as_product_box">
                                            <div class="as_product_img">
                                                <img src={Rudraksh8} alt="" class="img-responsive" />
                                            </div>
                                            <div>
                                                <h4 class="as_subheading">८ मुखी (रुद्राक्ष)</h4>
                                                <span class="as_price">₹20 <del>₹80</del> <span class="as_orange">(60% off)</span></span>
                                            </div>
                                            <button class="as_btn" style={{ marginTop: "15px" }} onClick={openWhatsApp}>खरेदी करा</button>

                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6">
                                        <div class="as_product_box">
                                            <div class="as_product_img">
                                                <img src={Rudraksh9} alt="" class="img-responsive" />
                                            </div>
                                            <div>
                                                <h4 class="as_subheading">९ मुखी (रुद्राक्ष)</h4>
                                                <span class="as_price">₹20 <del>₹80</del> <span class="as_orange">(60% off)</span></span>
                                            </div>
                                            <button class="as_btn" style={{ marginTop: "15px" }} onClick={openWhatsApp}>खरेदी करा</button>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >

        </>
    )
}

export default Shop