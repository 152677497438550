import React from 'react'
import CommonTitle from '../components/CommonTitle'


import puja1 from "../images/puja1.jpeg"
import puja2 from "../images/puja2.jpeg"
import puja3 from "../images/puja3.jpeg"
import puja4 from "../images/puja4.jpeg"
import puja5 from "../images/puja5.jpeg"
import puja6 from "../images/puja6.jpeg"
import puja7 from "../images/puja7.jpeg"
import puja8 from "../images/puja8.jpeg"


const Review = () => {
    return (
        <>
            <div class="as_main_wrapper">
                <CommonTitle title="पूजा माहिती" />
                <section class="as_blog_wrapper as_padderTop80 as_padderBottom80">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">

                                <div class="row">
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div class="as_blog_box">
                                            <div class="as_blog_img">
                                                <a href="blog_detail.html"><img src={puja1} alt="" class="img-responsive" /></a>
                                                <span class="as_btn">नारायण नागबली</span>
                                            </div>

                                            <h4 class="as_subheading"><a href="blog_left_detail.html"> नारायण नागबली.</a></h4>
                                            <p class="as_font14 as_margin0">नारायण नागबली पूजा ही पितृ दोष आणि कालसर्प दोष निवारणासाठी केली जाते. या पूजेच्या माध्यमातून अपूर्ण इच्छांचे शांतीकरण आणि कुळातील अनिष्ट प्रभावांचे निर्मूलन केले जाते. नारायण नागबली पूजेने वंशाची समृद्धी आणि कुटुंबातील सुख-समाधान प्राप्त होते..</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div class="as_blog_box">
                                            <div class="as_blog_img">
                                                <a href="blog_detail.html"><img src={puja8} alt="" class="img-responsive" /></a>
                                                <span class="as_btn">कालसर्पयोग</span>
                                            </div>

                                            <h4 class="as_subheading"><a href="blog_left_detail.html">कालसर्पयोग</a></h4>
                                            <p class="as_font14 as_margin0">कालसर्पयोग हा ज्योतिष शास्त्रातील एक महत्त्वपूर्ण योग आहे. हा योग निर्माण होतो जेव्हा कुंडलीतील सर्व ग्रह राहू आणि केतू या ग्रहांच्या प्रभावाखाली येतात. या योगामुळे व्यक्तीच्या जीवनात विविध अडचणी, आर्थिक समस्यां, आरोग्य समस्यां, आणि मानसिक अशांतता निर्माण होऊ शकतात.</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div class="as_blog_box">
                                            <div class="as_blog_img">
                                                <a href="blog_detail.html"><img src={puja3} alt="" class="img-responsive" /></a>
                                                <span class="as_btn">नक्षत्र शांती</span>
                                            </div>

                                            <h4 class="as_subheading"><a href="blog_left_detail.html">नक्षत्र शांती</a></h4>
                                            <p class="as_font14 as_margin0">नक्षत्र शांती पूजा ही ज्योतिष शास्त्रातील विशेष पूजा आहे जी व्यक्तीच्या जन्मनक्षत्राच्या अशुभ प्रभावांना दूर करण्यासाठी केली जाते. जन्मनक्षत्रातील दोषांमुळे आरोग्य, आर्थिक समस्या, मानसिक अशांतता, आणि वैवाहिक जीवनात अडचणी येऊ शकतात..</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div class="as_blog_box">
                                            <div class="as_blog_img">
                                                <a href="blog_detail.html"><img src={puja7} alt="" class="img-responsive" /></a>
                                                <span class="as_btn">नवग्रह शांती</span>
                                            </div>

                                            <h4 class="as_subheading"><a href="blog_left_detail.html">नवग्रह शांती</a></h4>
                                            <p class="as_font14 as_margin0">नवग्रह शांती ही एक पद्धत आहे ज्यामुळे व्यक्तीला ग्रहांच्या नकारात्मक प्रभावांवर नियंत्रण मिळतो. ह्या शांतीमध्ये विविध प्रकारचे पूजन, मंत्र, यंत्र आणि उपाय शामिल आहेत, ज्यामुळे व्यक्तीला स्थिरता आणि समृद्धी मिळते..</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div class="as_blog_box">
                                            <div class="as_blog_img">
                                                <a href="blog_detail.html"><img src={puja5} alt="" class="img-responsive" /></a>
                                                <span class="as_btn">वास्तु शांती</span>
                                            </div>

                                            <h4 class="as_subheading"><a href="blog_left_detail.html"> वास्तु शांती</a></h4>
                                            <p class="as_font14 as_margin0">वास्तु शांती ही एक विशेष विधी आहे ज्यामुळे घर किंवा कार्यालयातील स्थानांतर केलेल्या वास्तविकता आणि ऊर्जा बदलण्यासाठी विविध उपाय घेतले जातात. ह्या शांतीमध्ये स्थापना, वास्तु उपचार, मंत्र, तंत्र, वास्तु डिझाइन नियम, आणि संस्कार घेतले जातात ज्यामुळे समृद्धी, समदृष्टी आणि सुखद वातावरण स्थापित होतो.</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div class="as_blog_box">
                                            <div class="as_blog_img">
                                                <a href="blog_detail.html"><img src={puja6} alt="" class="img-responsive" /></a>
                                                <span class="as_btn">पितृदोष</span>
                                            </div>

                                            <h4 class="as_subheading"><a href="blog_left_detail.html">पितृदोष</a></h4>
                                            <p class="as_font14 as_margin0">पितृदोष हा एक ज्योतिषशास्त्रीय अवस्था आहे ज्यामुळे पूर्वजांच्या कर्मांच्या निर्धारित कारणांमुळे व्यक्तीला समस्या होऊ शकते. ह्या दोषामुळे व्यक्तीला विविध समस्यांची अनुभवी होतात, जसे की संघर्ष, स्वास्थ्य समस्या, आर्थिक कठीणाई, कुटुंबातील विवाह अशांचा संघटन.</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div class="as_blog_box">
                                            <div class="as_blog_img">
                                                <a href="blog_detail.html"><img src={puja4} alt="" class="img-responsive" /></a>
                                                <span class="as_btn">पितृदोष</span>
                                            </div>

                                            <h4 class="as_subheading"><a href="blog_left_detail.html">पितृदोष</a></h4>
                                            <p class="as_font14 as_margin0">पितृदोष हा एक ज्योतिषशास्त्रीय अवस्था आहे ज्यामुळे पूर्वजांच्या कर्मांच्या निर्धारित कारणांमुळे व्यक्तीला समस्या होऊ शकते. ह्या दोषामुळे व्यक्तीला विविध समस्यांची अनुभवी होतात, जसे की संघर्ष, स्वास्थ्य समस्या, आर्थिक कठीणाई, कुटुंबातील विवाह अशांचा संघटन.</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div class="as_blog_box">
                                            <div class="as_blog_img">
                                                <a href="blog_detail.html"><img src={puja2} alt="" class="img-responsive" /></a>
                                                <span class="as_btn">नारायण नागबली</span>
                                            </div>

                                            <h4 class="as_subheading"><a href="blog_left_detail.html"> नारायण नागबली</a></h4>
                                            <p class="as_font14 as_margin0">नारायण नागबली पूजा ही पितृ दोष आणि कालसर्प दोष निवारणासाठी केली जाते. या पूजेच्या माध्यमातून अपूर्ण इच्छांचे शांतीकरण आणि कुळातील अनिष्ट प्रभावांचे निर्मूलन केले जाते. नारायण नागबली पूजेने वंशाची समृद्धी आणि कुटुंबातील सुख-समाधान प्राप्त होते..</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Review