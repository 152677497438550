import React from 'react'
import WhyChoose from '../components/WhyChoose'
import CommonTitle from '../components/CommonTitle'
import WhatCustomer from '../components/WhatCustomer'

const Service = () => {
    return (
        <>
            <div class="as_main_wrapper as_service_page">\
                <CommonTitle title="सेवा" />


                <section class="as_service_wrapper as_padderTop50 as_padderBottom80">
                    <div class="container">
                        <div class="row">

                            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                <div class="as_service_box text-center">
                                    <span class="as_icon">
                                        <img src="assets/images/svg/service1.svg" alt="" />
                                    </span>

                                    <h4 class="as_subheading">वास्तुशास्त्र</h4>
                                    <p>वास्तुशास्त्र हे एक विज्ञान आहे ज्यात घरांची, कार्यालयांची किंवा इतर संरचनांची योजना, निर्माण आणि स्थापन करण्याच्या संबंधात नियम, तंत्रशास्त्र, आस्थेतील तत्व आणि ग्रहांच्या अस्तित्वाच्या अस्तित्वाच्या अस्तित्वाच्या अस्तित्वाच्या अस्तित्व</p>
                                    <a href="service_detail.html" class="as_link">पुढे वाचा</a>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                <div class="as_service_box text-center">
                                    <span class="as_icon">
                                        <img src="assets/images/svg/service2.svg" alt="" />
                                    </span>

                                    <h4 class="as_subheading">जन्म पत्रिका</h4>
                                    <p>जन्म पत्रिका ही एक विशेष दस्तऐवज आहे ज्यात व्यक्तीच्या जन्माची तारीख, वेळ, आणि ठिकाण विचारली जाते. ती वास्तविक आणि धार्मिक कार्यपत्रक असून, त्यामुळे व्यक्तीचे व्यक्तिमत्व आणि भविष्य विचारले जातात.</p>
                                    <a href="service_detail.html" class="as_link">पुढे वाचा</a>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                <div class="as_service_box text-center">
                                    <span class="as_icon">
                                        <img src="assets/images/svg/service3.svg" alt="" />
                                    </span>

                                    <h4 class="as_subheading">मांगलिक दोष</h4>
                                    <p>मांगलिक दोष हे एक ज्योतिषशास्त्रीय संकेत आहे, ज्यामुळे कुंडलीतील मंगळ ग्रहाचा दोष म्हणजे ज्यात आपल्या व्यक्तीच्या लग्नाच्या गोळ्यावर ग्रहांचा प्रभाव असतो. हे दोष त्यांच्या विवाहाच्या विचारांत एक प्रमुख कारक म्हणून गण्यात.</p>
                                    <a href="service_detail.html" class="as_link">पुढे वाचा</a>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                <div class="as_service_box text-center">
                                    <span class="as_icon">
                                        <img src="assets/images/svg/service4.svg" alt="" />
                                    </span>

                                    <h4 class="as_subheading">लाल किताब</h4>
                                    <p>लाल किताब हा एक ज्योतिषशास्त्रीय ग्रंथ आहे, ज्यातील टिप्स आणि उपाय विविध समस्यांच्या निराकरणासाठी दिलेले आहेत. ह्या किताबामध्ये कुंडलीतील ग्रहांच्या स्थितीच्या आधारावर विविध समस्यांच्या उपचारांची सूचना दिलेली आहे, ज्यामुळे ज्योतिषशास्त्रातील विविध पहायले जातात.</p>
                                    <a href="service_detail.html" class="as_link">पुढे वाचा</a>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                <div class="as_service_box text-center">
                                    <span class="as_icon">
                                        <img src="assets/images/svg/service5.svg" alt="" />
                                    </span>

                                    <h4 class="as_subheading">क्रिस्टल बॉल</h4>
                                    <p>क्रिस्टल बॉल हे एक प्रकारचे गोला असते जो क्रिस्टल किंवा कंद मध्ये बनवलेला असतो. या बॉलचा उपयोग विविध संस्कृतीतील ज्योतिषशास्त्र, वास्तुशास्त्र, आणि उपचारांसाठी केला जातो. .</p>
                                    <a href="service_detail.html" class="as_link">पुढे वाचा</a>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                <div class="as_service_box text-center">
                                    <span class="as_icon">
                                        <img src="assets/images/svg/service6.svg" alt="" />
                                    </span>

                                    <h4 class="as_subheading">कुंडली दोष</h4>
                                    <p>कुंडलीतील दोष हा ज्योतिषशास्त्रातील एक व्यापक विचार आहे. ह्या दोषांमध्ये मुख्यपणे ग्रहांच्या स्थितीच्या आधारे कुंडलीत असलेल्या संघर्षांचा वर्णन केला जातो. उदाहरणार्थ, मांगलिक दोष, शनि साडेसाती, राहू केतु दोष यांची तारखा आणि प्रतिक्रिया कुंडलीत विशेष स्थाने ग्रहांच्या विविध प्रभावांमुळे होतात.</p>
                                    <a href="service_detail.html" class="as_link">पुढे वाचा</a>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                <div class="as_service_box text-center">
                                    <span class="as_icon">
                                        <img src="assets/images/svg/service7.svg" alt="" />
                                    </span>

                                    <h4 class="as_subheading">टॅरो वाचन</h4>
                                    <p>टॅरो वाचन हा एक प्रकारचा ज्योतिषशास्त्राचा उपक्रम आहे ज्यामध्ये विविध प्रकारच्या कार्ड्सचा उपयोग केला जातो. ह्या कार्ड्समध्ये प्रत्येक कार्ड एक विशेष प्रकारच्या परिस्थित्यांसाठी प्रतिनिधित्व करतो आणि वाचनाद्वारे व्यक्तीला समस्यांचे संदेश देतो किंवा भविष्यातील प्रकारच्या समाधानांची मदत करतो.</p>
                                    <a href="service_detail.html" class="as_link">पुढे वाचा</a>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                <div class="as_service_box text-center">
                                    <span class="as_icon">
                                        <img src="assets/images/svg/service8.svg" alt="" />
                                    </span>

                                    <h4 class="as_subheading">पाम वाचन</h4>
                                    <p>पाम वाचन हा वेशभूषांचा एक रूप आहे ज्या व्यक्ती बॉल यांची तीळी वापरून विविध प्रकारच्या प्रश्नांची मदत करते. या वाचनात व्यक्तीला त्यांच्या भविष्याच्या प्रारंभिक संकेतांचा उल्लेख करण्यात योग्य आहे, जसे की संघर्ष, सौभाग्य, संतान, आणि कर्मफल.</p>
                                    <a href="service_detail.html" class="as_link">पुढे वाचा</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <WhyChoose />

                <section class="as_customer_wrapper as_padderBottom80 as_padderTop80">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 text-center">
                                <h1 class="as_heading as_heading_center">आमचे ग्राहक काय म्हणतात</h1>
                                <p class="as_font14 as_margin0 as_padderBottom50">कृष्ण शास्त्री - आपल्याला भविष्यातील अडचणींवर सोपे उपाय आणि मार्गदर्शन देणारा विश्वासार्ह ज्योतिषी...</p>
                                <WhatCustomer />
                            </div>

                        </div>
                    </div>
                </section>

            </div >

        </>
    )
}

export default Service