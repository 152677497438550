import React from 'react'

const CommonTitle = ({ title }) => {
    return (
        <section class="as_breadcrum_wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <h1>{title}</h1>
                        <ul class="breadcrumb">
                            <li><a href="#">मुख्यापुष्ठ</a></li>
                            <li>{title}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CommonTitle