import React, { useState } from 'react'
import CommonTitle from '../components/CommonTitle'

const Contact = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        const whatsappMessage = `पूर्ण नाव: ${name}%0Aइमेल: ${email}%0Aसंदेश: ${message}`;
        const whatsappUrl = `https://wa.me/917420907274?text=${whatsappMessage}`;
        window.open(whatsappUrl, '_blank');
    }


    function handleMailto() {
        window.location.href =
            "mailto:krishnajoahi7274@gmail.com?subject=Your%20Subject%20Here&body=Your%20message%20here.";
    }


    function handleCallNow() {
        const phoneNumber = "7420907274";
        window.location.href = `tel:+${phoneNumber}`;
    }


    return (
        <>
            <CommonTitle title="आमच्याशी संपर्क साधा" />
            <section class="as_contact_section as_padderTop80">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="as_contact_info">
                                <h1 class="as_heading">संपर्क माहिती</h1>
                                <p class="as_font14 as_margin0">तुम्हाला अधिक माहितीसाठी किंवा सेवा घेण्यासाठी कृपया आमच्याशी संपर्क साधा.</p>

                                <div class="row">
                                    <div class="col-xl-6 col-lg-12 col-md-6 col-sm-6 col-xs-12">
                                        <div class="as_info_box">
                                            <span class="as_icon"><img src="assets/images/svg/headphone.svg" alt="" /></span>
                                            <div class="as_info">
                                                <h5>आम्हाला कॉल करा</h5>
                                                <p class="as_margin0 as_font14" onClick={handleCallNow}>7420907274</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-12 col-md-6 col-sm-6 col-xs-12">
                                        <div class="as_info_box">
                                            <span class="as_icon"><img src="assets/images/svg/mail1.svg" alt="" /></span>
                                            <div class="as_info">
                                                <h5>ई-मेल करा</h5>
                                                <p class="as_margin0 as_font14"><a onClick={handleMailto}> krishnajoahi7274@gmail.com</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="as_contact_form">
                                <h4 class="as_subheading">एक प्रश्न आहे तर आमच्याशी संपर्क करा</h4>

                                <form onSubmit={handleSubmit}>
                                    <div class="form-group">
                                        <label>पूर्ण नाव</label>
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            class="form-control"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label>इमेल</label>
                                        <input
                                            type="text"
                                            name="email"
                                            id="email"
                                            class="form-control"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label>संदेश</label>
                                        <textarea
                                            name="message"
                                            id="message"
                                            class="form-control"
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                        ></textarea>
                                    </div>
                                    <button type='submit' class="as_btn">पाठवा</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section class="as_map_section">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8401.560921854018!2d73.53536172985781!3d19.93801237820021!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bddf4b167cecc65%3A0x3b8e4b5e1a81d285!2sAkhil%20Bhartiya%20Shree%20Swami%20Samarth%20Gurupeeth%2C%20Trimbakeshwar!5e1!3m2!1sen!2sin!4v1720931721001!5m2!1sen!2sin"
                    width="100%" height="743px" allowfullscreen="" loading="lazy"></iframe>
            </section>

        </>



    )
}

export default Contact