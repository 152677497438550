import React from 'react'

const Header = () => {

    function handleMailto() {
        window.location.href =
            "mailto:krishnajoahi7274@gmail.com?subject=Your%20Subject%20Here&body=Your%20message%20here.";
    }


    function handleCallNow() {
        const phoneNumber = "7420907274";
        window.location.href = `tel:+${phoneNumber}`;
    }




    return (
        <>
            <section class="as_header_wrapper">
                <div class="as_logo">
                    <h3 style={{ fontWeight: "bold", color: "#ff7010" }}></h3>
                    {/* <a href="javascript:;"><img src="assets/images/logo.svg" alt="" /></a> */}
                </div>
                <div class="as_header_detail">
                    <div class="as_info_detail">
                        <ul>
                            <li>
                                <a href="javascript:;">
                                    <div class="as_infobox">
                                        <span class="as_infoicon">
                                            <img src="assets/images/svg/headphone.svg" alt="" />
                                        </span>
                                        <span class="as_orange">आत्ताच काल करा</span>
                                        <a onClick={handleCallNow}>7420907274</a>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:;">
                                    <div class="as_infobox">
                                        <span class="as_infoicon">
                                            <img src="assets/images/svg/mail1.svg" alt="" />
                                        </span>
                                        <span class="as_orange">आताच संदेश पाठवा -</span>
                                        <a onClick={handleMailto}>krishnajoahi7274@gmail.com</a>
                                      
                                    </div>
                                </a>
                            </li>
                        </ul>

                    </div>
                    <div class="as_menu_wrapper">
                        <span class="as_toggle">
                            <img src="assets/images/svg/menu.svg" alt="" />
                           
                        </span>
                        
                        <div class="as_menu">
                       
                            <ul>
                          
                                <li><a href="/">मुख्यापुष्ठ</a></li>
                                <li><a href="/about">आमच्याबद्दल</a></li>
                                <li><a href="/service">सेवा</a></li>
                                <li><a href="/shop">खरेदी करा</a></li>
                                <li><a href="/review">पूजा माहिती</a></li>
                                <li><a href="/contact">आमच्याशी संपर्क साधा</a></li>
                            </ul>
                        </div>
                    </div>
                    <span class="as_body_overlay"></span>
                </div>
            </section>

        </>
    )
}

export default Header