import React from 'react'

const WhyChoose = () => {
    return (
        <>

            <section class="as_whychoose_wrapper as_padderTop80 as_padderBottom50">
                <div class="container">
                    <div class="row as_verticle_center">
                        <div class="col-lg-3 col-md-12">
                            <h1 class="as_heading">आम्हाला का निवडा</h1>
                            <p class="as_font14 as_margin0">कृष्ण शास्त्री - आपल्याला भविष्यातील अडचणींवर सोपे उपाय आणि मार्गदर्शन देणारा विश्वासार्ह ज्योतिषी..</p>
                        </div>
                        <div class="col-lg-9 col-md-12">
                            <div class="row">
                                <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                    <div class="as_whychoose_box text-center">
                                        <span class="as_number"><span><span data-from="0" data-to="512"
                                            data-speed="5000">512</span>+</span><img src="assets/images/svg/shape.svg" alt="" /></span>
                                        <h4>पात्र ज्योतिषी</h4>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                    <div class="as_whychoose_box text-center">
                                        <span class="as_number"><span><span data-from="0" data-to="62"
                                            data-speed="5000">62</span>+</span><img src="assets/images/svg/shape.svg" alt="" /></span>
                                        <h4>यश कुंडली</h4>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                    <div class="as_whychoose_box text-center">
                                        <span class="as_number"><span><span data-from="0" data-to="94"
                                            data-speed="5000">94</span>+</span><img src="assets/images/svg/shape.svg" alt="" /></span>
                                        <h4>जगभरातील कार्यालये</h4>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                    <div class="as_whychoose_box text-center">
                                        <span class="as_number"><span><span data-from="0" data-to="452"
                                            data-speed="5000">452+</span>+</span><img src="assets/images/svg/shape.svg" alt="" /></span>
                                        <h4>दशलक्ष ग्राहकांचा विश्वास</h4>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                    <div class="as_whychoose_box text-center">
                                        <span class="as_number"><span><span data-from="0" data-to="12"
                                            data-speed="5000">12</span>+</span><img src="assets/images/svg/shape.svg" alt="" /></span>
                                        <h4>वर्षाचा अनुभव</h4>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                    <div class="as_whychoose_box text-center">
                                        <span class="as_number"><span><span data-from="0" data-to="652"
                                            data-speed="5000">652+</span>+</span><img src="assets/images/svg/shape.svg" alt="" /></span>
                                        <h4>कुंडलीचे प्रकार</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WhyChoose