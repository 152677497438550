import React from 'react'
import CommonTitle from '../components/CommonTitle'
import WhatCustomer from '../components/WhatCustomer'
import WhyChoose from '../components/WhyChoose'
import about1 from "../images/about1.jpg"

const About = () => {
    return (
        <>
            <div class="as_main_wrapper">
                <CommonTitle title="आमच्याबद्दल" />

                <section class="as_about_wrapper as_padderTop80 as_padderBottom80">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="as_about_slider">
                                    <div>
                                        <div class="as_aboutimg text-right">
                                            <img src={about1} alt="" class="img-responsive" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <h1 class="as_heading">ज्योतिष शास्त्राबद्दल जाणून घ्या</h1>
                                <p>ज्योतिषी कृष्ण शास्त्री हे वैदिक ज्योतिष, हस्तरेखा शास्त्र आणि अंकशास्त्रामध्ये २५ वर्षांहून अधिक अनुभव असलेले प्रसिद्ध ज्योतिषी आहेत. नाशिक मध्ये स्थायिक असलेल्या कृष्ण शास्त्री यांनी हजारो ग्राहकांना जीवनातील आव्हानांचा सामना करण्यास आणि योग्य निर्णय घेण्यास मदत केली आहे. त्यांच्या कौशल्यामध्ये वैयक्तिक कुंडली वाचन, रत्न सल्ले आणि वास्तु सल्लामसलत यांचा समावेश आहे.</p>
                                <p> भविष्यवाणी आणि व्यावहारिक सल्ल्यासाठी ते ओळखले जातात, पारंपारिक ज्ञान आणि आधुनिक दृष्टिकोन यांचा उत्तम संगम साधतात. विविध माध्यमांमध्ये त्यांचा उल्लेख झाला आहे आणि त्यांच्या साप्ताहिक ज्योतिष कॉलमसाठी त्यांचे अनेक अनुयायी आहेत. व्यक्तींना समाधानी जीवनाकडे नेण्यासाठी ते वचनबद्ध आहेत आणि व्यापक प्रेक्षकांपर्यंत पोहोचण्यासाठी कार्यशाळा आणि ऑनलाइन सत्रांचे आयोजन करतात..</p>
                                <a href="javascript:;" class="as_btn">read more</a>

                                <div class="as_contact_expert">
                                    <span class="as_icon">
                                    </span>
                                    <div>
                                        <h5 class="as_white">ज्योतिषांशी संपर्क साधा</h5>
                                        <h1 class="as_orange">7420907274</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <WhyChoose />
                <WhatCustomer />
            </div>

        </>
    )
}

export default About