import React, { useState } from 'react'
import WhyChoose from '../components/WhyChoose'
import WhatCustomer from '../components/WhatCustomer'
import { Modal, Button } from "react-bootstrap"
import about1 from "../images/about1.jpg"
import about2 from "../images/about1.png"
import stone1 from "../images/stone1.png"
import stone2 from "../images/stone2.png"
import stone3 from "../images/stone3.png"
import stone4 from "../images/stone4.png"
import stone5 from "../images/stone5.png"
import stone6 from "../images/stone6.png"
import stone7 from "../images/stone7.png"
import stone8 from "../images/stone8.png"
import stone11 from "../images/stone9.png"
import puja1 from "../images/puja1.jpeg"
import puja2 from "../images/puja2.jpeg"
import puja3 from "../images/puja3.jpeg"
import yantra1 from "../images/yantra1.jpeg"
import yantra2 from "../images/yantra2.webp"
import yantra3 from "../images/yantra3.webp"
import yantra4 from "../images/yantra4.webp"
import yantra5 from "../images/yantra5.jpg"
import yantra6 from "../images/yantra6.webp"



const getRashi = (date) => {

    const month = parseInt(date.split("/")[1], 10);
    const day = parseInt(date.split('/')[0], 10);

    if ((month === 3 && day >= 21) || (month === 4 && day <= 19)) return 'मेष';
    if ((month === 4 && day >= 20) || (month === 5 && day <= 20)) return 'वृषभ';
    if ((month === 5 && day >= 21) || (month === 6 && day <= 20)) return 'मिथुन';
    if ((month === 6 && day >= 21) || (month === 7 && day <= 22)) return 'कर्क';
    if ((month === 7 && day >= 23) || (month === 8 && day <= 22)) return 'सिंह';
    if ((month === 8 && day >= 23) || (month === 9 && day <= 22)) return 'कन्या';
    if ((month === 9 && day >= 23) || (month === 10 && day <= 22)) return 'तुला';
    if ((month === 10 && day >= 23) || (month === 11 && day <= 21)) return 'वृश्चिक';
    if ((month === 11 && day >= 22) || (month === 12 && day <= 21)) return 'धनु';
    if ((month === 12 && day >= 22) || (month === 1 && day <= 19)) return 'मकर';
    if ((month === 1 && day >= 20) || (month === 2 && day <= 18)) return 'कुंभ';
    if ((month === 2 && day >= 19) || (month === 3 && day <= 20)) return 'मीन';
    return 'अज्ञात';

}


const Home = () => {

    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState({
        birthDate: "",
        birthTime: "",
        birthPlace: "",
    });
    const [result, setResult] = useState("")

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const handleSubmit = () => {
        const rashi = getRashi(formData.birthDate);
        setResult(`आपले राशि नाव: ${rashi}`);
        handleShow();
    };

    return (
        <>
            <div class="as_main_wrapper">
                <section class="as_banner_wrapper">
                    <div class="container-fluid">
                        <div class="row as_verticle_center">
                            <div class="col-lg-6 order-lg-1 col-md-6 order-md-1 col-sm-12 order-sm-1 col-12 order-2">
                                <div class="as_banner_detail">
                                    <h1>तुमच्या भविष्याची कुंजी, कृष्णाजी शास्त्री!</h1>
                                    <h5>श्री स्वामी समर्थ ज्योतिष केंद्र</h5>
                                    <a href="/about" class="as_btn">पुढे वाचा</a>
                                </div>
                            </div>
                            <div class="col-lg-6 order-lg-2 col-md-6 order-md-2 col-sm-12 order-sm-2 col-12 order-1">
                                <div class="as_banner_img text-center">
                                    <img src={about2} alt="" class="img-responsive" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="as_about_wrapper as_padderTop80 as_padderBottom80">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="as_about_slider">
                                    <div>
                                        <div class="as_aboutimg text-right">
                                            <img src={about1} alt="" class="img-responsive" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                <h1 class="as_heading">ज्योतिष शास्त्रीबद्दल </h1>
                                <p>कृष्णा शास्त्री हे एक प्रख्यात अभियंता आहेत ज्यांनी आपल्या क्षेत्रात उत्कृष्ट कामगिरी केली आहे. त्यांचा विविध प्रकल्पांवरील अनुभव आणि तांत्रिक कौशल्य त्यांना एक विश्वासार्ह आणि गुणवान व्यावसायिक बनवतात. त्यांनी वेळेवर आणि उच्च गुणवत्तेच्या सेवांचा पुरवठा करून आपल्या ग्राहकांचे समाधान प्राप्त केले आहे. त्यांचे मार्गदर्शन आणि सल्ला अनेक प्रकल्पांच्या यशस्वीतेला महत्त्वपूर्ण ठरले आहे,</p>
                                <p>कृष्णा शास्त्री यांच्या कार्यक्षमतेमुळे आणि व्यावसायिकतेमुळे त्यांना आपल्या क्षेत्रात एक विशिष्ट ओळख मिळाली आहे. त्यांचा ग्राहकांशी असलेला संवाद आणि त्यांच्या गरजा ओळखून दिलेली सेवा हा त्यांचा प्रमुख गुण आहे. त्यामुळेच, कृष्णा शास्त्री हे आपल्या तांत्रिक गरजांसाठी एक विश्वसनीय आणि सक्षम व्यक्ती आहेत.</p>
                                <a href="javascript:;" class="as_btn">पुढे वाचा</a>

                                <div class="as_contact_expert">
                                    <span class="as_icon">
                                    </span>
                                    <div>
                                        <h5 class="as_white">आमच्याशी संपर्क साधा</h5>
                                        <h1 class="as_orange">7420907274</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="as_service_wrapper as_padderTop80 as_padderBottom80">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 text-center">
                                <h1 class="as_heading as_heading_center">सेवा</h1>
                                <p class="as_font14 as_padderBottom5">कृष्ण शास्त्री यांच्या सल्ल्यामुळे अनेक समस्यांवर सोपे आणि प्रभावी उपाय मिळाले आहेत..</p>
                            </div>

                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <div class="as_service_box text-center">
                                    <span class="as_icon">
                                        <img src="assets/images/svg/service1.svg" alt="" />
                                    </span>

                                    <h4 class="as_subheading">वास्तुशास्त्र</h4>
                                    <p>वास्तुशास्त्र हे एक विज्ञान आहे ज्यात घरांची, कार्यालयांची किंवा इतर संरचनांची योजना, निर्माण आणि स्थापन करण्याच्या संबंधात नियम, तंत्रशास्त्र, आस्थेतील तत्व आणि ग्रहांच्या अस्तित्वाच्या अस्तित्वाच्या अस्तित्वाच्या अस्तित्वाच्या अस्तित्व</p>

                                    <a href="service_detail.html" class="as_link">पुढे वाचा</a>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <div class="as_service_box text-center">
                                    <span class="as_icon">
                                        <img src="assets/images/svg/service2.svg" alt="" />
                                    </span>

                                    <h4 class="as_subheading">जन्म पत्रिका</h4>
                                    <p>जन्म पत्रिका ही एक विशेष दस्तऐवज आहे ज्यात व्यक्तीच्या जन्माची तारीख, वेळ, आणि ठिकाण विचारली जाते. ती वास्तविक आणि धार्मिक कार्यपत्रक असून, त्यामुळे व्यक्तीचे व्यक्तिमत्व आणि भविष्य विचारले जातात.</p>

                                    <a href="service_detail.html" class="as_link">पुढे वाचा</a>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <div class="as_service_box text-center">
                                    <span class="as_icon">
                                        <img src="assets/images/svg/service3.svg" alt="" />
                                    </span>

                                    <h4 class="as_subheading">मांगलिक दोष</h4>
                                    <p>मांगलिक दोष हे एक ज्योतिषशास्त्रीय संकेत आहे, ज्यामुळे कुंडलीतील मंगळ ग्रहाचा दोष म्हणजे ज्यात आपल्या व्यक्तीच्या लग्नाच्या गोळ्यावर ग्रहांचा प्रभाव असतो. हे दोष त्यांच्या विवाहाच्या विचारांत एक प्रमुख कारक म्हणून गण्यात.</p>

                                    <a href="service_detail.html" class="as_link">पुढे वाचा</a>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <div class="as_service_box text-center">
                                    <span class="as_icon">
                                        <img src="assets/images/svg/service4.svg" alt="" />
                                    </span>

                                    <h4 class="as_subheading">लाल किताब</h4>
                                    <p>लाल किताब हा एक ज्योतिषशास्त्रीय ग्रंथ आहे, ज्यातील टिप्स आणि उपाय विविध समस्यांच्या निराकरणासाठी दिलेले आहेत. ह्या किताबामध्ये कुंडलीतील ग्रहांच्या स्थितीच्या आधारावर विविध समस्यांच्या उपचारांची सूचना दिलेली आहे, ज्यामुळे ज्योतिषशास्त्रातील विविध पहायले जातात.</p>

                                    <a href="service_detail.html" class="as_link">पुढे वाचा</a>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <div class="as_service_box text-center">
                                    <span class="as_icon">
                                        <img src="assets/images/svg/service5.svg" alt="" />
                                    </span>

                                    <h4 class="as_subheading">क्रिस्टल बॉल</h4>
                                    <p>क्रिस्टल बॉल हे एक प्रकारचे गोला असते जो क्रिस्टल किंवा कंद मध्ये बनवलेला असतो. या बॉलचा उपयोग विविध संस्कृतीतील ज्योतिषशास्त्र, वास्तुशास्त्र, आणि उपचारांसाठी केला जातो. .</p>

                                    <a href="service_detail.html" class="as_link">पुढे वाचा</a>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <div class="as_service_box text-center">
                                    <span class="as_icon">
                                        <img src="assets/images/svg/service6.svg" alt="" />
                                    </span>

                                    <h4 class="as_subheading">कुंडली दोष</h4>
                                    <p>कुंडलीतील दोष हा ज्योतिषशास्त्रातील एक व्यापक विचार आहे. ह्या दोषांमध्ये मुख्यपणे ग्रहांच्या स्थितीच्या आधारे कुंडलीत असलेल्या संघर्षांचा वर्णन केला जातो. उदाहरणार्थ, मांगलिक दोष, शनि साडेसाती, राहू केतु दोष यांची तारखा आणि प्रतिक्रिया कुंडलीत विशेष स्थाने ग्रहांच्या विविध प्रभावांमुळे होतात.</p>

                                    <a href="service_detail.html" class="as_link">पुढे वाचा</a>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <div class="as_service_box text-center">
                                    <span class="as_icon">
                                        <img src="assets/images/svg/service7.svg" alt="" />
                                    </span>

                                    <h4 class="as_subheading">पाम वाचन</h4>
                                    <p>पाम वाचन हा वेशभूषांचा एक रूप आहे ज्या व्यक्ती बॉल यांची तीळी वापरून विविध प्रकारच्या प्रश्नांची मदत करते. या वाचनात व्यक्तीला त्यांच्या भविष्याच्या प्रारंभिक संकेतांचा उल्लेख करण्यात योग्य आहे, जसे की संघर्ष, सौभाग्य, संतान, आणि कर्मफल.</p>

                                    <a href="service_detail.html" class="as_link">पुढे वाचा</a>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <div class="as_service_box text-center">
                                    <span class="as_icon">
                                        <img src="assets/images/svg/service8.svg" alt="" />
                                    </span>

                                    <h4 class="as_subheading">डली दोष</h4>
                                    <p>डली दोष हा वेशभूषांचा एक रूप आहे ज्या व्यक्ती बॉल यांची तीळी वापरून विविध प्रकारच्या प्रश्नांची मदत करते. या वाचनात व्यक्तीला त्यांच्या भविष्याच्या प्रारंभिक संकेतांचा उल्लेख करण्यात योग्य आहे, जसे की संघर्ष, सौभाग्य, संतान, आणि कर्मफल.</p>

                                    <a href="service_detail.html" class="as_link">पुढे वाचा</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="as_know_sign_wrapper as_padderBottom80 as_padderTop80">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 text-center">
                                <h1 class="as_heading as_heading_center">तुमचे राशीचे नाव जाणून घ्या</h1>
                                <p class="as_font14 as_margin0 as_padderBottom50">आपल्या भविष्याचे मार्गदर्शन करणारा ज्योतिषी कृष्ण शास्त्री - ज्यांच्याशी परामर्शाने आपल्याला समाधानाचा मार्ग सापडतो.</p>


                                <div class="as_sign_form text-left">
                                    <ul>
                                        <li class="as_form_box">
                                            <h3 class="as_subheading">जन्मतारीख</h3>
                                            <div class="as_input_feild">
                                                <input type="text" name="birthDate" class="form-control as_datepicker" placeholder="DD/MM/YYYY" value={formData.birthDate} onChange={handleChange} />
                                                <span><img src="assets/images/svg/date.svg" alt="" /></span>
                                            </div>
                                        </li>
                                        <li class="as_form_box">
                                            <h3 class="as_subheading">जन्मवेळ</h3>
                                            <div class="as_input_feild">
                                                <input type="text" name="birthTime" class="form-control as_timepicker" placeholder="08:00" value={formData.birthTime} onChange={handleChange} />
                                                <span><img src="assets/images/svg/time.svg" alt="" /></span>
                                            </div>
                                        </li>
                                        <li class="as_form_box">
                                            <h3 class="as_subheading">जन्म स्थळ</h3>
                                            <div class="as_input_feild">
                                                <input type="text" name="birthPlace" class="form-control" placeholder="Enter City Name...." value={formData.birthPlace} onChange={handleChange} />
                                                <span><img src="assets/images/svg/map1.svg" alt="" /></span>
                                            </div>
                                        </li>
                                        <li class="as_form_box">
                                            <a href="javascript:;" class="as_btn" onClick={handleSubmit}>राशिचक्र शोधा</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <WhatCustomer />
            </div>
            <WhyChoose />




            <section class="as_blog_wrapper as_padderTop80 as_padderBottom80">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 text-center">
                            <h1 class="as_heading as_heading_center">पूजा माहिती</h1>
                            <p class="as_font14 as_margin0 as_padderBottom20">कृष्ण शास्त्री यांच्याकडून पूजेची माहिती - आपले जीवन सुख, शांती आणि समृद्धीने भरुन टाका..</p>

                            <div class="text-left">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div class="as_blog_box">
                                            <div class="as_blog_img">
                                                <a href="blog_detail.html"><img src={puja1} alt="" class="img-responsive" /></a>
                                                <span class="as_btn">नारायण नागबली</span>
                                            </div>

                                            <h4 class="as_subheading"><a href="blog_left_detail.html"> नारायण नागबली</a></h4>
                                            <p class="as_font14 as_margin0">नारायण नागबली पूजा ही पितृ दोष आणि कालसर्प दोष निवारणासाठी केली जाते. या पूजेच्या माध्यमातून अपूर्ण इच्छांचे शांतीकरण आणि कुळातील अनिष्ट प्रभावांचे निर्मूलन केले जाते. नारायण नागबली पूजेने वंशाची समृद्धी आणि कुटुंबातील सुख-समाधान प्राप्त होते..</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div class="as_blog_box">
                                            <div class="as_blog_img">
                                                <a href="blog_detail.html"><img src={puja2} alt="" class="img-responsive" /></a>
                                                <span class="as_btn">कालसर्पयोग</span>
                                            </div>
                                            <h4 class="as_subheading"><a href="blog_left_detail.html">कालसर्पयोग</a></h4>
                                            <p class="as_font14 as_margin0">कालसर्पयोग हा ज्योतिष शास्त्रातील एक महत्त्वपूर्ण योग आहे. हा योग निर्माण होतो जेव्हा कुंडलीतील सर्व ग्रह राहू आणि केतू या ग्रहांच्या प्रभावाखाली येतात. या योगामुळे व्यक्तीच्या जीवनात विविध अडचणी, आर्थिक समस्यां, आरोग्य समस्यां, आणि मानसिक अशांतता निर्माण होऊ शकतात..</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <div class="as_blog_box">
                                            <div class="as_blog_img">
                                                <a href="blog_detail.html"><img src={puja3} alt="" class="img-responsive" /></a>
                                                <span class="as_btn">नक्षत्र शांती</span>
                                            </div>
                                            <h4 class="as_subheading"><a href="blog_left_detail.html"> नक्षत्र शांती</a></h4>
                                            <p class="as_font14 as_margin0">नक्षत्र शांती पूजा ही ज्योतिष शास्त्रातील विशेष पूजा आहे जी व्यक्तीच्या जन्मनक्षत्राच्या अशुभ प्रभावांना दूर करण्यासाठी केली जाते. जन्मनक्षत्रातील दोषांमुळे आरोग्य, आर्थिक समस्या, मानसिक अशांतता, आणि वैवाहिक जीवनात अडचणी येऊ शकतात..</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="as_product_wrapper as_padderBottom80 as_padderTop80 as_product_page">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 text-center">
                            <h1 class="as_heading as_heading_center">यंत्रे</h1>
                            <p class="as_font14 as_margin0 as_padderBottom20">आमच्याकडे सिद्ध जप अनुष्ठान झालेले यंत्र मिळतील.</p>

                            <div class="row as_product_slider">
                                <div class="col-lg-3 col-md-6">
                                    <div class="as_product_box">
                                        <div class="as_product_img">
                                            <img src={yantra1} alt="" class="img-responsive" />
                                        </div>
                                        <div>
                                            <h4 class="as_subheading">श्री सम्पूर्ण कालसर्प यंत्र</h4>
                                            <span class="as_price">$20 <del>$80</del> <span class="as_orange">(60% off)</span></span>
                                        </div>
                                        <button class="as_btn" style={{ marginTop: "15px" }}>खरेदी करा</button>

                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="as_product_box">
                                        <div class="as_product_img">
                                            <img src={yantra2} alt="" class="img-responsive" />
                                        </div>
                                        <div>
                                            <h4 class="as_subheading">श्री सम्पूर्ण वास्तुयन्त्रम यंत्र</h4>
                                            <span class="as_price">$20 <del>$80</del> <span class="as_orange">(60% off)</span></span>
                                        </div>
                                        <button class="as_btn" style={{ marginTop: "15px" }}>खरेदी करा</button>

                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="as_product_box">
                                        <div class="as_product_img">
                                            <img src={yantra3} alt="" class="img-responsive" />
                                        </div>
                                        <div>
                                            <h4 class="as_subheading">श्री सम्पूर्ण नवग्रहयंत्रम </h4>
                                            <span class="as_price">$20 <del>$80</del> <span class="as_orange">(60% off)</span></span>
                                        </div>
                                        <button class="as_btn" style={{ marginTop: "15px" }}>खरेदी करा</button>

                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="as_product_box">
                                        <div class="as_product_img">
                                            <img src={yantra4} alt="" class="img-responsive" />
                                        </div>
                                        <div>

                                            <h4 class="as_subheading">श्री संम्पूर्ण यंत्रम</h4>
                                            <span class="as_price">$20 <del>$80</del> <span class="as_orange">(60% off)</span></span>
                                        </div>
                                        <button class="as_btn" style={{ marginTop: "15px" }}>खरेदी करा</button>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="as_product_box">
                                        <div class="as_product_img">
                                            <img src={yantra5} alt="" class="img-responsive" />
                                        </div>
                                        <div>
                                            <h4 class="as_subheading">श्री सम्पूर्ण  यंत्र</h4>
                                            <span class="as_price">$20 <del>$80</del> <span class="as_orange">(60% off)</span></span>
                                        </div>
                                        <button class="as_btn" style={{ marginTop: "15px" }}>खरेदी करा</button>

                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="as_product_box">
                                        <div class="as_product_img">
                                            <img src={yantra6} alt="" class="img-responsive" />
                                        </div>
                                        <div>
                                            <h4 class="as_subheading">श्री सम्पूर्ण व्यापारवृद्धी यंत्र</h4>
                                            <span class="as_price">$20 <del>$80</del> <span class="as_orange">(60% off)</span></span>
                                        </div>
                                        <button class="as_btn" style={{ marginTop: "15px" }}>खरेदी करा</button>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section class="as_product_wrapper as_padderBottom80 as_padderTop80">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 text-center">
                            <h1 class="as_heading as_heading_center">रत्ने</h1>
                            <p class="as_font14 as_margin0 as_padderBottom20">आमच्याकडे सिद्ध जप अनुष्ठान रत्न मिळतील.</p>
                            <div class="row as_product_slider">
                                <div class="col-lg-3 col-md-6">
                                    <div class="as_product_box">
                                        <div class="as_product_img">
                                            <img src={stone1} alt="" class="img-responsive" />
                                        </div>
                                        <div>
                                            <h4 class="as_subheading">रुबी</h4>
                                            <span class="as_price">$20 <del>$80</del> <span class="as_orange">(60% off)</span></span>
                                        </div>
                                        <button class="as_btn" style={{ marginTop: "15px" }}>खरेदी करा</button>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="as_product_box">
                                        <div class="as_product_img">
                                            <img src={stone2} alt="" class="img-responsive" />
                                        </div>
                                        <div>
                                            <h4 class="as_subheading">पुष्कराज</h4>
                                            <span class="as_price">$20 <del>$80</del> <span class="as_orange">(60% off)</span></span>
                                        </div>
                                        <button class="as_btn" style={{ marginTop: "15px" }}>खरेदी करा</button>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="as_product_box">
                                        <div class="as_product_img">
                                            <img src={stone7} alt="" class="img-responsive" />
                                        </div>
                                        <div>
                                            <h4 class="as_subheading">पन्ना</h4>
                                            <span class="as_price">$20 <del>$80</del> <span class="as_orange">(60% off)</span></span>
                                        </div>
                                        <button class="as_btn" style={{ marginTop: "15px" }}>खरेदी करा</button>

                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="as_product_box">
                                        <div class="as_product_img">
                                            <img src={stone3} alt="" class="img-responsive" />
                                        </div>
                                        <div>
                                            <h4 class="as_subheading">नीलम</h4>
                                            <span class="as_price">$20 <del>$80</del> <span class="as_orange">(60% off)</span></span>
                                        </div>
                                        <button class="as_btn" style={{ marginTop: "15px" }}>खरेदी करा</button>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="as_product_box">
                                        <div class="as_product_img">
                                            <img src={stone5} alt="" class="img-responsive" />
                                        </div>
                                        <div>
                                            <h4 class="as_subheading">माणिक्य </h4>
                                            <span class="as_price">$20 <del>$80</del> <span class="as_orange">(60% off)</span></span>
                                        </div>
                                        <button class="as_btn" style={{ marginTop: "15px" }}>खरेदी करा</button>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="as_product_box">
                                        <div class="as_product_img">
                                            <img src={stone4} alt="" class="img-responsive" />

                                        </div>
                                        <div>

                                            <h4 class="as_subheading">मोती</h4>
                                            <span class="as_price">$20 <del>$80</del> <span class="as_orange">(60% off)</span></span>
                                        </div>
                                        <button class="as_btn" style={{ marginTop: "15px" }}>खरेदी करा</button>

                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="as_product_box">
                                        <div class="as_product_img">
                                            <img src={stone11} alt="" class="img-responsive" />


                                        </div>
                                        <div>

                                            <h4 class="as_subheading">मुंगा</h4>
                                            <span class="as_price">$20 <del>$80</del> <span class="as_orange">(60% off)</span></span>
                                        </div>
                                        <button class="as_btn">खरेदी करा</button>

                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="as_product_box">
                                        <div class="as_product_img">
                                            <img src={stone8} alt="" class="img-responsive" />
                                        </div>
                                        <div>
                                            <h4 class="as_subheading">गोमेद</h4>
                                            <span class="as_price">$20 <del>$80</del> <span class="as_orange">(60% off)</span></span>
                                        </div>
                                        <button class="as_btn" style={{ marginTop: "15px" }}>खरेदी करा</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal
                show={show}
                onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>तुमची राशी</Modal.Title>
                </Modal.Header>
                <Modal.Body>{result}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default Home